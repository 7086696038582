/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

$pp-hover-color: #f8f9fa;

:root {
  --pp-hover-color: #f8f9fa;
}

[data-bs-theme="dark"] {
  --pp-hover-color: #383b40;
}

.hover-bg:hover {
  background-color: var(--pp-hover-color);
  cursor: pointer;
}

.provider-select {
  > div:first-of-type {
    min-height: 40px;
    display: flex;
    align-items: center;
    background-color: transparent;

    > div:has(> .provider-toggle) {
      padding: 0px 8px;
      display: flex;
      align-items: center;

      > .provider-toggle {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  .provider-option {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: $gray-200;
    }
  }

  img {
    width: 60px;
    object-fit: contain;
  }
}
